import {defineComponent} from "vue";

export default defineComponent({
		computed: {
			// @ts-ignore
			languages() {
				// @ts-ignore
				return this.$root.languagesStore.languageList;
			},
			// @ts-ignore
			translateList() {
				// @ts-ignore
				return this.$root.languagesStore.translateList;
			},
			pathTranslations() {
				let defaultRouteNames = {
					'profile': '/profile',
					'profile-welcome': '/profile/welcome',
					'profile-personal': '/profile/personal',
					'profile-notifications': '/profile/notifications',
					'profile-password': '/profile/password',
					'profile-student-data': '/profile/student-data',
					'profile-student-cooperative': '/profile/student-cooperative',
					'profile-education': '/profile/education',
					'profile-biography': '/profile/biography',
					'profile-saved-adverts': '/profile/saved-adverts',
					'profile-saved-searches': '/profile/saved-searches',
					'profile-signed-up-adverts': '/profile/signed-up-adverts',
					'profile-documents': '/profile/documents',
					'student-agreement': '/profile/student-agreement',
					'advert-detail': '/allas/:slug/:advertid',
					'profile-attendance': 'attendance',
				}
				const languagesList = {
					en: Object.assign(defaultRouteNames, {'advert-detail': '/job/:slug/:advertid'}),
					hu: Object.assign(defaultRouteNames, {'advert-detail': '/allas/:slug/:advertid'}),
					sr: {
						'profile': '/profil',
						'profile-welcome': '/profile/dobrodosli',
						'profile-personal': '/profil/licno',
						'profile-notifications': '/profil/obavestenja',
						'profile-password': '/profil/lozinka',
						'profile-student-data': '/profil/student-data',
						'profile-student-cooperative': '/profil/student-cooperative',
						'profile-education': '/profil/education',
						'profile-biography': '/profil/biografija',
						'profile-saved-adverts': '/profil/sacuvani-oglasi',
						'profile-saved-searches': '/profil/sacuvane-pretrage',
						'profile-signed-up-adverts': '/profil/sacuvane-prijave',
						'profile-documents': '/profil/documents',
						'student-agreement': '/profil/student-agreement',
						'advert-detail': '/posao/:slug/:advertid',
					},
					sl: Object.assign(defaultRouteNames, {'advert-detail': '/zaposlitev/:slug/:advertid'}),
				}
				if (!(Object.keys(languagesList)).includes(this.getLang())) {
					return Object.assign(languagesList, {[this.getLang()]: defaultRouteNames});
				}
				return languagesList;
			}
		},
		methods: {
			// @ts-ignore
			translate(key: string, params?: { [propName: string]: any }): string {
				if (params && Object.keys(params)?.length > 0) {
					let result = this.translateList[key] ?? key;
					if (result !== key) {
						const keys = Object.keys(params);
						keys.forEach(translateKey => {
							if (result.includes(translateKey)) {
								result = result.replace('{' + translateKey + '}', params[translateKey]);
							}
						});
					}
					return result;
				}

				// @ts-ignore
				return this.translateList[key] ?? key;
			},
			getLang(): string {
				// @ts-ignore
				const currentLang = this.$root.languagesStore.currentLang;
				// @ts-ignore
				const defaultLang = this.$root.languagesStore.defaultLang;
				if (this.pathTranslations?.hasOwnProperty(currentLang)) {
					return currentLang;
				}
				return defaultLang;
			},
			advertLocalizeLink(slug: string, advertId: string) {
				const url = this.pathTranslations[this.getLang()]['advert-detail'];
				return url.replace(':slug', slug).replace(':advertid', advertId);
			},
			getSimpleLocalizeRoute(route: string): string {
				return this.pathTranslations[this.getLang()][route];
			}
		},
	}
);
